/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 tree.glb --transform
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Tree(props) {
  const { nodes, materials } = useGLTF('/tree.glb')
  return (
    <group {...props} dispose={null}>
      <ambientLight intensity={0.3} />
      <mesh geometry={nodes.plant006.geometry} material={materials.fattree} position={[-13.3, 0, 0]} />
      <mesh geometry={nodes.plant.geometry} material={materials.fattree} position={[-10.14, 0, 0]} />
      <mesh geometry={nodes.fattree.geometry} material={materials.fattree} position={[0, 2.93, 0]} >
      <meshPhysicalMaterial 
            color="black"  
            roughness={1}
            emissive={'#000'}
            clearcoat={1}
            reflectivity={0.2}
            metalness={0}
            iridescence={0.1}
            iridescenceIOR={1}
            iridescenceThicknessRange={[100,1000]}         
          />
      </mesh>
    </group>
  )
}

useGLTF.preload('/tree.glb')

