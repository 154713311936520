/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 saura.glb --transform
*/

import { useGLTF, useAnimations,useScroll } from '@react-three/drei'
import React, { useRef,useLayoutEffect, useEffect,useState } from 'react'
import {useFrame} from '@react-three/fiber'
import gsap from 'gsap'
import { easing } from "maath"

export function Robot(props) {
  const robot = useRef()
  const { nodes, materials, animations } = useGLTF('/saura.glb')
  const { ref,actions, names } = useAnimations(animations)
  const [hovered, setHovered] = useState(false)
  const [index, setIndex] = useState(3)
  // console.log(actions);

 
  const scroll = useScroll()
  const tl = useRef()
  const halo = useRef()

  useFrame((state, delta)=>{
    tl.current.seek(scroll.offset * tl.current.duration())
  })

  useLayoutEffect(()=> {
    tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.inOut'}})

    tl.current
    //.to(robot.current.rotation, {y:}, 2)
    .to(robot.current.position, {x: 1}, 4)
    .to(robot.current.scale, {x: 1,y:1,z:1}, 4)

    .to(robot.current.rotation, {y: 1}, 6)   
    .to(robot.current.position, {x: -1}, 6)

    .to(robot.current.rotation, {y: 0}, 11)
    .to(robot.current.rotation, {x: 1}, 11)
    .to(robot.current.position, {x: 0}, 11)
    .to(robot.current.scale, {x: 0.8,y:0.8,z:0.8}, 11)

    .to(robot.current.rotation, {y: 0}, 13)
    .to(robot.current.rotation, {x: -1}, 13)    
    .to(robot.current.position, {x: 0}, 13)

    .to(robot.current.rotation, {y: 0}, 16)   
    .to(robot.current.rotation, {x: 0}, 16) 
    .to(robot.current.position, {x: 0}, 16)    

    .to(robot.current.rotation, {y: 0}, 20)   
    .to(robot.current.rotation, {x: 0}, 20) 
    .to(robot.current.position, {x: 0}, 20)   

  },[])

  useEffect(() => void (document.body.style.cursor = hovered ? "pointer" : "auto"), [hovered])

    // Change animation when the index changes
    useEffect(() => {
      // Reset and fade in animation after an index has been changed
      actions[names[index]].reset().fadeIn(0.5).play()
      // In the clean-up phase, fade it out
      return () => actions[names[index]].fadeOut(0.5)
    }, [index, actions, names])

    useFrame((state, delta) => {
      // Animate the selection halo
      easing.damp3(halo.current.scale, hovered ? 1.15 : 1, 0.2, delta)
      easing.dampC(halo.current.material.color, hovered ? "yellow" : "white", 0.2, delta)
    })

  return (
    <group {...props} dispose={null} ref={ref}>
       <group ref={robot}>
          <group name="Scene">
            <group name="Armature">
              <primitive object={nodes.Hips} />
              <skinnedMesh name="Wolf3D_Avatar"   
                onPointerOver={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                onClick={() => setIndex((index + 1) % names.length)} 
                geometry={nodes.Wolf3D_Avatar.geometry} 
                material={materials.Wolf3D_Avatar} 
                skeleton={nodes.Wolf3D_Avatar.skeleton} 
                morphTargetDictionary={nodes.Wolf3D_Avatar.morphTargetDictionary} 
                morphTargetInfluences={nodes.Wolf3D_Avatar.morphTargetInfluences} />
            </group>
          </group>
        </group>
        <mesh ref={halo} receiveShadow position={[-0.6,3, -2]}>
          {/* <circleGeometry args={[4, 64]} />
          <meshStandardMaterial />  */}
        </mesh>
    </group>
   
  )
}

useGLTF.preload('/saura.glb')
