/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 key.glb --transform
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Key(props) {
  const { nodes, materials } = useGLTF('/key.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation-x={-2.4} scale={0.0002}>
        <mesh geometry={nodes.Object_2.geometry} material={materials['07___Default']} />
        <mesh geometry={nodes.Object_3.geometry} material={materials['13___Default']} />
        <mesh geometry={nodes.Object_4.geometry} material={materials['14___Default']} />
      </group>
    </group>
  )
}

useGLTF.preload('/key.glb')
