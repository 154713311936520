/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 notebook.glb --transform
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Notebook(props) {
  const { nodes, materials } = useGLTF('/notebook.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.04}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.Sheets} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials.Cover} />
        <mesh geometry={nodes.Cube001_2.geometry} material={materials.Binding} />
      </group>
    </group>
  )
}

useGLTF.preload('/notebook.glb')
