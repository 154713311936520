/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 saurabhbm.glb --transform
*/

import { useGLTF, useAnimations,useScroll } from '@react-three/drei'
import React, { useRef,useLayoutEffect, useEffect,useState } from 'react'
import {useFrame} from '@react-three/fiber'
import gsap from 'gsap'

export function Saubm(props) {
  const robot = useRef()
  const { nodes, materials, animations } = useGLTF('/saurabhbm.glb')
  const { ref,actions, names } = useAnimations(animations)
  const [index] = useState(4)

  const scroll = useScroll()
  const tl = useRef()

  useFrame((state, delta)=>{
    tl.current.seek(scroll.offset * tl.current.duration())
  })

  useLayoutEffect(()=> {
    tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.inOut'}})

    tl.current
    .to(robot.current.position, {x: -2,y:-20}, 0)
    .to(robot.current.position, {x: -2,y:-20}, 2)
    //.to(robot.current.rotation, {y:}, 2)
    .to(robot.current.position, {x: -2,y:-20}, 4)
    .to(robot.current.scale, {x: 0.5,y:0.5,z:0.5}, 4)

    .to(robot.current.position, {x: -2,y:-20}, 6)
   
    .to(robot.current.position, {x: -2,y:-18}, 11)
  
    .to(robot.current.position, {x: 2,y:-3}, 13)
    .to(robot.current.rotation, {y:-1,x:-0.1}, 14)
    .to(robot.current.scale, {x:0.5,y:0.5,z:0.5}, 14)  
    // .to(robot.current.position, {x: -2,y:-3}, 15)
    // .to(robot.current.position, {x: -2,y:-3}, 16)
    // .to(robot.current.position, {x: -2,y:-3}, 17)
    // .to(robot.current.position, {x: -2,y:-3}, 18)
    // .to(robot.current.position, {x: -2,y:-3}, 19)  
    .to(robot.current.scale, {x:1,y:1,z:1}, 20)  
    .to(robot.current.rotation, {y:1}, 20)
    .to(robot.current.position, {x: -2,y:-6.4}, 20)   

  },[])

  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    actions[names[5]].reset().fadeIn(0.5).play()
    // In the clean-up phase, fade it out
    return () => actions[names[index]].fadeOut(0.5)
  }, [index, actions, names])

  return (  
    <group ref={ref} >
      <group ref={robot} {...props} dispose={null}>
        <group name="Scene">
          <group name="Armature" scale={0.30}>
            <primitive object={nodes.Hips} />
            <skinnedMesh name="Wolf3D_Avatar" geometry={nodes.Wolf3D_Avatar.geometry} material={materials.Wolf3D_Avatar} skeleton={nodes.Wolf3D_Avatar.skeleton} morphTargetDictionary={nodes.Wolf3D_Avatar.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Avatar.morphTargetInfluences} />
          </group>
        </group>
      </group>
    </group>
   
  )
}

useGLTF.preload('/saurabhbm.glb')
