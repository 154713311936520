/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 comp.glb --transform
*/

import React, { useRef,useLayoutEffect } from 'react'
import { useGLTF,useScroll } from '@react-three/drei'
import {useFrame} from '@react-three/fiber'
import gsap from 'gsap'

export function Comp(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/comp.glb')

  const scroll = useScroll()
  const tl = useRef()

  useFrame((state, delta)=>{
    tl.current.seek(scroll.offset * tl.current.duration())
  })

  useLayoutEffect(()=> {
    tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.inOut'}})

    tl.current
    //.to(robot.current.rotation, {y:}, 2)
    //2.5,-1.5,-2
    .to(group.current.rotation, {y:1.5,x:-0.25}, 2)
    .to(group.current.scale, {x: 0.35,y:0.35,z:0.35}, 2)
    .to(group.current.position, {x: 6}, 2)


    .to(group.current.position, {x: 6}, 4)
    .to(group.current.position, {y: -10}, 4)


    .to(group.current.position, {x: 1}, 6)
    .to(group.current.rotation, {y:1.5,x:-0.25}, 6)
    .to(group.current.position, {y: -12}, 6)

    .to(group.current.position, {x: -1}, 8)
    .to(group.current.rotation, {y:2.5,x:-0.25}, 8)
    .to(group.current.position, {y: -14}, 8)

    .to(group.current.position, {x: -1}, 10)
    .to(group.current.rotation, {y:2.5,x:-0.25}, 10)
    .to(group.current.scale, {x: 0.35,y:0.35,z:0.35}, 10)
    .to(group.current.position, {y: -16}, 10)


    .to(group.current.position, {x: -1}, 12)
    .to(group.current.rotation, {y:2.5,x:-0.25}, 12)
    .to(group.current.scale, {x: 0.35,y:0.35,z:0.35}, 12)
    .to(group.current.position, {y: -17}, 12)



    .to(group.current.position, {x: -1}, 14)
    .to(group.current.rotation, {y:3.4,x:-0.25}, 14)


    .to(group.current.position, {x: -1}, 16)

    .to(group.current.position, {x: -1}, 18)

    .to(group.current.position, {x: -1}, 20)



  },[])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Sketchfab_model" position={[0.21, 3.36, -6.55]} rotation={[-Math.PI / 2, 0, 0]} scale={0.29}>
          <group name="mainframeobjcleanermaterialmergergles" position={[-14.05, -25.17, -12.36]}>
            <group name="Object_2">
              <mesh name="Object_10" geometry={nodes.Object_10.geometry} material={materials.lambert27SG} />
              <mesh name="Object_11" geometry={nodes.Object_11.geometry} material={materials.lambert29SG} />
              <mesh name="Object_12" geometry={nodes.Object_12.geometry} material={materials.pasted__lambert19SG} />
              <mesh name="Object_13" geometry={nodes.Object_13.geometry} material={materials.pasted__lambert8SG} />
              <mesh name="Object_14" geometry={nodes.Object_14.geometry} material={materials.lambert11SG} />
              <mesh name="Object_15" geometry={nodes.Object_15.geometry} material={materials.lambert13SG} />
              <mesh name="Object_16" geometry={nodes.Object_16.geometry} material={materials.lambert14SG} />
              <mesh name="Object_17" geometry={nodes.Object_17.geometry} material={materials.lambert15SG} />
              <mesh name="Object_18" geometry={nodes.Object_18.geometry} material={materials.lambert22SG} />
              <mesh name="Object_19" geometry={nodes.Object_19.geometry} material={materials.lambert23SG} />
              <mesh name="Object_20" geometry={nodes.Object_20.geometry} material={materials.lambert28SG} />
              <mesh name="Object_21" geometry={nodes.Object_21.geometry} material={materials.lambert30SG} />
              <mesh name="Object_22" geometry={nodes.Object_22.geometry} material={materials.lambert3SG} />
              <mesh name="Object_23" geometry={nodes.Object_23.geometry} material={materials.lambert4SG} />
              <mesh name="Object_24" geometry={nodes.Object_24.geometry} material={materials.lambert5SG} />
              <mesh name="Object_25" geometry={nodes.Object_25.geometry} material={materials.lambert6SG} />
              <mesh name="Object_26" geometry={nodes.Object_26.geometry} material={materials.pasted__lambert8SG} />
              <mesh name="Object_27" geometry={nodes.Object_27.geometry} material={materials.pasted__lambert4SG} />
              <mesh name="Object_28" geometry={nodes.Object_28.geometry} material={materials.pasted__pasted__lambert10SG} />
              <mesh name="Object_29" geometry={nodes.Object_29.geometry} material={materials.pasted__lambert8SG} />
              <mesh name="Object_3" geometry={nodes.Object_3.geometry} material={materials.initialShadingGroup} />
              <mesh name="Object_30" geometry={nodes.Object_30.geometry} material={materials.pasted__pasted__lambert10SG} />
              <mesh name="Object_4" geometry={nodes.Object_4.geometry} material={materials.pasted__pasted__lambert10SG} />
              <mesh name="Object_5" geometry={nodes.Object_5.geometry} material={materials.lambert16SG} />
              <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.lambert17SG} />
              <mesh name="Object_7" geometry={nodes.Object_7.geometry} material={materials.lambert18SG} />
              <mesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.pasted__lambert19SG} />
              <mesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials.lambert21SG} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/comp.glb')
