/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 bag.glb --transform
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Bag(props) {
  const { nodes, materials } = useGLTF('/bag.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.26, 0.92, -0.22]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.24, 0.24, 0.33]}>
        <mesh geometry={nodes['1_1'].geometry} material={materials['Material.004']} />
        <mesh geometry={nodes['1_2'].geometry} material={materials['Material.002']} />
        <mesh geometry={nodes['1_3'].geometry} material={materials['Material.003']} />
        <mesh geometry={nodes['1_4'].geometry} material={materials['Material.004']} />
      </group>
    </group>
  )
}

useGLTF.preload('/bag.glb')
