/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 head.glb --transform
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Head(props) {
  const { nodes, materials } = useGLTF('/head.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.cable.geometry} material={nodes.cable.material} position={[-4.04, 0.04, 2.17]} rotation={[-0.44, 0, 0]} scale={[0.7, 1, 1]} />
      <mesh geometry={nodes.cable001.geometry} material={nodes.cable001.material} position={[-4.04, 0.04, -2.18]} rotation={[-0.55, 0, 0]} scale={[0.7, 1, 1]} />
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.001']} position={[-4.03, 2.24, 0]} scale={[0.34, 0.14, 1.95]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.001']} position={[-4.03, -1.6, 1.3]} rotation={[-1.06, 0, 0]} scale={[0.92, 0.21, 0.97]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.001']} position={[-4.03, -1.63, -1.3]} rotation={[1.06, 0, 0]} scale={[0.92, 0.21, 0.97]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.002']} position={[-4.03, -1.31, 0.88]} rotation={[-1.05, 0, 0]} scale={[0.96, 0.27, 1.03]} />
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.001']} position={[-4.03, -1.42, -0.84]} rotation={[1.07, 0, 0]} scale={[0.96, 0.27, 1.03]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.001']} position={[-4.03, -1.43, 1.08]} rotation={[-1.05, 0, 0]} scale={[0.97, 0.09, 1.04]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material.001']} position={[-4.03, -1.8, 1.54]} rotation={[-1.06, 0, 0]} scale={[0.53, 0.11, 0.71]} />
      <mesh geometry={nodes.Cylinder006.geometry} material={materials['Material.001']} position={[-4.03, -1.53, -1.05]} rotation={[1.07, 0, 0]} scale={[0.97, 0.09, 1.04]} />
      <mesh geometry={nodes.Cylinder007.geometry} material={materials['Material.001']} position={[-4.04, -1.78, -1.56]} rotation={[1.05, 0, 0]} scale={[0.53, 0.11, 0.71]} />
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.001']} position={[-4.04, 0.08, 2.08]} rotation={[-Math.PI, 0, 0]} scale={[0.27, 0.25, 0.13]} />
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.001']} position={[-4.04, 0.08, -2.09]} rotation={[-Math.PI, 0, 0]} scale={[0.27, 0.25, 0.13]} />
    </group>
  )
}

useGLTF.preload('/head.glb')
