/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 laptop.glb --transform
*/

import React, { useRef,useLayoutEffect } from 'react'
import { useGLTF,useScroll } from '@react-three/drei'
import {useFrame} from '@react-three/fiber'
import gsap from 'gsap'

export function Laptop(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/laptop.glb')
 // const { actions } = useAnimations(animations, group)

  const scroll = useScroll()
  const tl = useRef()

  useFrame((state, delta)=>{
    tl.current.seek(scroll.offset * tl.current.duration())
  })

  useLayoutEffect(()=> {
    tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.inOut'}})

    tl.current
    //.to(robot.current.rotation, {y:}, 2)
    //2.5,-1.5,-2
    .to(group.current.rotation, {y:-0.4}, 1.8)
    .to(group.current.position, {x:1.2,y:-3,z:2}, 1.8)
    .to(group.current.scale, {x: 3,y:3,z:3}, 1.8)

    .to(group.current.rotation, {y: 1}, 12)   
    .to(group.current.position, {x: -1}, 12)

    .to(group.current.rotation, {y: 0}, 15)
    .to(group.current.rotation, {x: 1}, 15)
    .to(group.current.position, {x: 0}, 15)
    .to(group.current.scale, {x: 0.8,y:0.8,z:0.8}, 15)

    .to(group.current.rotation, {y: 0}, 17)
    .to(group.current.rotation, {x: -1}, 17)    
    .to(group.current.position, {x: 0}, 17)

    .to(group.current.rotation, {y: 0}, 20)   
    .to(group.current.rotation, {x: 0}, 20) 
    .to(group.current.position, {x: 0}, 20)    

    .to(group.current.rotation, {y: 0}, 26)   
    .to(group.current.rotation, {x: 0}, 26) 
    .to(group.current.position, {x: 0}, 26)   

  },[])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Cube005" rotation={[0.03, 0, 0]}>
          <mesh name="Cube006_1" geometry={nodes.Cube006_1.geometry} material={materials.InteriorBlack} />
          <mesh name="Cube006_2" geometry={nodes.Cube006_2.geometry} material={materials.IndicatorLights} />
        </group>
        <mesh name="Cube006" geometry={nodes.Cube006.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube011" geometry={nodes.Cube011.geometry} material={materials.InteriorBlack} position={[0, 0, 0.09]} rotation={[-3.11, 0, -Math.PI]} />
        <mesh name="Cube007" geometry={nodes.Cube007.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube008" geometry={nodes.Cube008.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube009" geometry={nodes.Cube009.geometry} material={materials.InteriorBlack} position={[-0.17, 0, 0.04]} rotation={[0.03, 0, 0]} />
        <mesh name="Cube010" geometry={nodes.Cube010.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube012" geometry={nodes.Cube012.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube013" geometry={nodes.Cube013.geometry} material={materials.InteriorBlack} position={[0, 0, -0.01]} rotation={[0.03, 0, 0]} />
        <mesh name="Cube014" geometry={nodes.Cube014.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube015" geometry={nodes.Cube015.geometry} material={materials.InteriorBlack} rotation={[0.03, 0, 0]} />
        <mesh name="Cube016" geometry={nodes.Cube016.geometry} material={nodes.Cube016.material} rotation={[0.03, 0, 0]} />
        <group name="Bottom001" position={[0, -0.01, -0.02]} rotation={[0.03, 0, 0]}>
          <mesh name="Cube030" geometry={nodes.Cube030.geometry} material={materials.LaptopSurface} />
          <mesh name="Cube030_1" geometry={nodes.Cube030_1.geometry} material={materials.Painted} />
        </group>
        <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.InteriorBlack} position={[0, 0, -0.01]} rotation={[0.03, 0, 0]} scale={0.02} />
        <mesh name="Bottom002" geometry={nodes.Bottom002.geometry} material={materials.LaptopSurface} rotation={[0.03, 0, 0]} />
        <mesh name="Bottom005" geometry={nodes.Bottom005.geometry} material={materials.SurfaceMetal} rotation={[0.03, 0, 0]} />
        <mesh name="Plane" geometry={nodes.Plane.geometry} material={materials.SurfaceMetal} />
        <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials.SurfaceMetal} rotation={[0.03, Math.PI / 2, 0]} />
        <mesh name="Cube031" geometry={nodes.Cube031.geometry} material={materials.Keyboard} position={[0, 0.01, -0.04]} rotation={[0.03, 0, 0]} />
        <group name="Cube002" position={[0, 0.1, -0.16]}>
          <mesh name="Cube004_1" geometry={nodes.Cube004_1.geometry} material={materials.LaptopSurface} />
          <mesh name="Cube004_2" geometry={nodes.Cube004_2.geometry} material={materials.SurfaceMetal} />
          <mesh name="Cube004_3" geometry={nodes.Cube004_3.geometry} material={materials.Painted} />
          <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.InteriorBlack} rotation={[-1.93, 0, 0]} />
          <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials.Keyboard} rotation={[-1.93, 0, 0]} />
          <mesh name="Cube003" geometry={nodes.Cube003.geometry} material={materials.Screen} rotation={[-1.93, 0, 0]} />
          <mesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials.LaptopSurface} rotation={[-1.93, 0, 0]} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/laptop.glb')
